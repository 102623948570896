import { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";

//---------------------------------------------------------------

const CustomTd = ({ text }) => {
  const [copyText, setCopyText] = useState();

  useEffect(() => {
    if (copyText) {
      navigator.clipboard.writeText(copyText);
    }
  }, [copyText]);

  return (
    <Tooltip title={copyText ? "copied ✔" : `copy ${text} 🗐`} placement="top">
      <td
        onClick={(event) => {
          event.stopPropagation();
          setCopyText(text);
        }}
        onMouseLeave={() => setCopyText(null)}
        style={{
          textTransform: "uppercase",
        }}
      >
        {text}
      </td>
    </Tooltip>
  );
};

export default CustomTd;
