import styled from 'styled-components';
import { motion } from 'framer-motion';

//-------------------------------------------
export const ContentItemTitle = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: grey;
  width: 100%;
  text-align: left;
`;

export const ContentItem = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: start;
  background-color: white;
  border-radius: 10px;
  padding: 5px 9px;
  //box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  //wrap with ellipsis

  cursor: pointer;
  &:hover {
    //no shadow
    box-shadow: none;
    background-color: rgb(255 255 255);

    //title dodgeblue
    ${ContentItemTitle} {
      color: dodgerblue;
    }
  }
`;

export const ContentItemContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 11px;
  i {
    font-size: 10px;
    color: grey;
    padding-bottom: 1px;
    //display: none;
  }
  div,
  span {
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .names_hidden {
    display: none;
    .see {
      color: dodgerblue;
    }
  }
  div {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .names {
    display: flex;
    flex-direction: row;
    gap: 3px;
  }
  @media (max-width: 700px) {
    .names {
      display: none;
    }
    .names_hidden {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
    }
  }
`;

