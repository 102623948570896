import { useState } from "react";
import styled from "styled-components";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  useFetchTourGroupsBySpecificDate,
  useFetchEntities,
} from "../../../../reactQueryHooks";

//--------------------------------------------------

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const DayOffButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  max-height: 50px;
  border-radius: 6px;
  color: white;
  font-size: 13px;
  cursor: pointer;
  background: ${({ isDayOff }) => {
    if (isDayOff) return "indianred";
    return "#76bf76";
  }};
  &:hover {
    //brightness 0.9
    filter: brightness(0.9);
  }
  user-select: none;
`;

const LeaveButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  max-height: 50px;
  border-radius: 6px;
  color: white;
  font-size: 13px;
  cursor: pointer;
  background: ${({ isDayOff: isLeave }) => {
    if (isLeave) return "indianred";
    return "#CFAEDA";
  }};
  &:hover {
    //brightness 0.9
    filter: brightness(0.9);
  }
  user-select: none;
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  user-select: none;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  height: 40px;
  gap: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  background: #f2f2f2;
  padding: 5px 8px;
  span {
    color: #3d3d3d;
  }
  &:hover {
    span {
      color: dodgerblue;
    }
    .delete-container {
      i {
        opacity: 1;
      }
    }
  }
  .delete-container {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: indianred;
      opacity: 0;
      cursor: pointer;
    }
  }
  user-select: none;
`;

const AddCommentInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  min-height: 50px;
  max-height: 50px;
`;

const ProductItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  font-size: 13px;
  //box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  .title {
    font-weight: bold;
    color: #797979;
    padding-left: 2px;
  }
`;

const ProductItemContainer = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  background: whitesmoke;
  padding: 8px;
  border-radius: 6px;

  span {
    //wrap text with ellipsis
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }

  i {
    color: #a3a3a3;
    cursor: pointer;
    &:hover {
      color: indianred;
    }
  }
`;

const GroupItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  font-size: 13px;
  //box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  .title {
    font-weight: bold;
    color: #797979;
    padding-left: 2px;
  }
`;

const GroupItemList = styled.div`
  width: 100%;
  padding: 0 5px 0 0;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const GroupItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: whitesmoke;
  display: none;
  flex-direction: row;
  border-radius: 6px;
`;

const GroupItemOverlayItem = styled.div`
  user-select: none;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a3a3a3;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: dodgerblue;
  }
  span {
    font-weight: bold;
    color: indianred;
  }
`;

const GroupItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  background: ${({ isSelected }) =>
    isSelected ? "rgb(208 248 208)" : "whitesmoke"};
  padding: 8px;
  border-radius: 6px;
  &:hover {
    ${GroupItemOverlay} {
      display: ${({ isSelected }) => (isSelected ? "none" : "flex")};
    }
  }
  .fa-check {
    display: ${({ isSelected }) => (isSelected ? "flex" : "none")};
  }
`;

//--------------------------------------------------

//--------------------------------------------------

const CalendarBoxOverlay = ({
  date,
  user,
  scheduleData,
  addOrUpdateUserDaySchedule,
}) => {
  const [commentInput, setCommentInput] = useState("");
  const hasScheduleData = scheduleData !== null;
  const isDayOff = hasScheduleData && scheduleData.isDayOff;
  const isLeave = hasScheduleData && scheduleData.isLeave;
  const hasComments = hasScheduleData && scheduleData.comments?.length > 0;
  const { data: entities } = useFetchEntities();
  const {
    data: tourGroupsBySpecificDate,
    isFetching: isFetchingBySpecificDate,
  } = useFetchTourGroupsBySpecificDate(moment(date).format("YYYY-MM-DD"));
  const dateHasTourGroups = tourGroupsBySpecificDate?.length > 0;

  //--------------------------------------------------
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        gap: "6px",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          // backgroundColor: "#e3eef1",
          border: "1px solid #e6e6e6",
          padding: "0 10px",
          alignItems: "center",
          minHeight: "50px",
          borderRadius: "6px",
          //boxShadow: "0px 1px 4px -1px rgba(0,0,0,0.3)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          <i
            style={{
              color: "#a3a3a3",
              fontSize: "12px",
            }}
            className="fas fa-user"
          ></i>
          <span>{user.label}</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            //color: "#7596b7",
          }}
        >
          <i
            style={{
              color: "#a3a3a3",
              fontSize: "12px",
            }}
            className="fas fa-calendar-day"
          ></i>
          <span>{moment(new Date(date)).format("DD.MM.YYYY")}</span>
        </div>
      </div>
      <ProductItemWrapper>
        <span className="title">Assigned schedule</span>
        {(scheduleData?.tourGroups?.length === 0 || !scheduleData) && (
          <span
            style={{
              flex: 1,
              textAlign: "center",
              color: "indianred",
              fontWeight: "bold",
            }}
          >
            NO SCHEDULE SET
          </span>
        )}
        {scheduleData?.tourGroups?.map((tourGroup, index) => {
          const product = entities.data.products.find(
            (product) => product._id === tourGroup?._data?.product_id
          );
          const option = product?.options.find(
            (option) => option._id === tourGroup?._data?.option_id
          );

          const startTime = product?.start_times?.find(
            (start_time) => start_time._id === tourGroup?._data?.start_time_id
          );

          const hasData = tourGroup._data !== null;
          return (
            <ProductItemContainer
              key={uuidv4()}
              title={
                !hasData
                  ? ""
                  : `${product?.title} - ${option?.title} @ ${startTime?.time_slot} (#${tourGroup._data.index})`
              }
            >
              {hasData ? (
                <>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span>{`${product?.title} - ${option?.title}`}</span>
                    <span>{`${startTime?.time_slot} `}</span>
                    <span>{`(#${tourGroup._data.index})`}</span>
                  </span>
                  <i
                    className="fa-solid fa-trash"
                    title="remove"
                    onClick={() => {
                      const newTourGroups = scheduleData.tourGroups.filter(
                        (p, i) => i !== index
                      );
                      addOrUpdateUserDaySchedule({
                        ...scheduleData,
                        user: user.value,
                        date: moment(new Date(date)).format("YYYY-MM-DD"),
                        isDayOff: false,
                        _id: scheduleData?._id, //backend checks if _id is truthy, if not, it creates a new schedule
                        tourGroups: newTourGroups,
                        notify: true,
                      });
                    }}
                  ></i>
                </>
              ) : (
                <span>Invalid group</span>
              )}
            </ProductItemContainer>
          );
        })}
      </ProductItemWrapper>
      <GroupItemWrapper>
        <span className="title">Available groups</span>
        {isFetchingBySpecificDate && <span>Fetching groups...</span>}
        {!isFetchingBySpecificDate && !dateHasTourGroups && (
          <span
            style={{
              color: "indianred",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            NO GROUPS
          </span>
        )}
        {!isFetchingBySpecificDate && dateHasTourGroups && (
          <GroupItemList>
            {tourGroupsBySpecificDate
              .filter((tg) => tg.visible_in_planner === true)
              .map((tourGroup) => {
                const product = entities.data.products.find(
                  (product) => product._id === tourGroup?.product_id
                );

                const option = product?.options.find(
                  (option) => option._id === tourGroup?.option_id
                );

                const startTime = product?.start_times?.find(
                  (start_time) => start_time._id === tourGroup?.start_time_id
                );

                const userData = entities.data.staff.find(
                  (staff) => staff._id === user.value
                );
                const isSelected = scheduleData?.tourGroups?.find(
                  (tg) => tg?._data?._id === tourGroup?._id
                );
                const productRoles = product?.crewRoles;
                const userRolesThatMatchProductRoles = userData.roles.filter(
                  (userRole) =>
                    productRoles.some(
                      (crewRoleId) => crewRoleId === userRole._id
                    )
                );
                const hasNoMatchingRoles =
                  userRolesThatMatchProductRoles.length === 0;

                return (
                  <GroupItemContainer key={uuidv4()} isSelected={isSelected}>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span>{`${product?.title} - ${option?.title}`}</span>
                      <span>{`${startTime?.time_slot} `}</span>
                      <span>{`(#${tourGroup.index})`}</span>
                    </span>
                    <i className="fa-solid fa-check"></i>
                    <GroupItemOverlay>
                      {hasNoMatchingRoles && (
                        <GroupItemOverlayItem key={uuidv4()}>
                          <span>Cannot be selected</span>
                        </GroupItemOverlayItem>
                      )}

                      {!hasNoMatchingRoles &&
                        userRolesThatMatchProductRoles.map((role) => {
                          return (
                            <GroupItemOverlayItem
                              key={uuidv4()}
                              onClick={() => {
                                addOrUpdateUserDaySchedule({
                                  ...scheduleData,
                                  user: user.value,
                                  date: moment(new Date(date)).format(
                                    "YYYY-MM-DD"
                                  ),
                                  isDayOff: false,
                                  isLeave: false,
                                  _id: scheduleData?._id, //backend checks if _id is truthy, if not, it creates a new schedule
                                  tourGroups: [
                                    ...(scheduleData?.tourGroups || []),
                                    {
                                      id: tourGroup._id,
                                      role: role._id,
                                    },
                                  ],
                                  notify: true,
                                });
                              }}
                            >
                              {role.title}
                            </GroupItemOverlayItem>
                          );
                        })}
                    </GroupItemOverlay>
                  </GroupItemContainer>
                );
              })}
          </GroupItemList>
        )}
      </GroupItemWrapper>
      <AddCommentInputContainer>
        <input
          style={{
            flex: 1,
            border: "none",
            outline: "none",
            padding: "3px",
            borderRadius: "6px",
            fontSize: "13px",
            color: "#3d3d3d",
            background: "whitesmoke",
            textAlign: "center",
          }}
          placeholder="Add comment"
          value={commentInput}
          onChange={(e) => setCommentInput(e.target.value)}
        ></input>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
            borderRadius: "6px",
            background: "whitesmoke",
            cursor: "pointer",
          }}
          onClick={() => {
            addOrUpdateUserDaySchedule({
              ...scheduleData,
              user: user.value,
              date: moment(new Date(date)).format("YYYY-MM-DD"),
              _id: scheduleData?._id, //backend checks if _id is truthy, if not, it creates a new schedule
              tourGroups: scheduleData?.tourGroups || [],
              comments: [
                ...(scheduleData?.comments || []),
                {
                  text: commentInput,
                  user: user.label,
                  date: moment(new Date()).format("DD-MM-YYYY HH:mm"),
                },
              ],
              notify: false,
            });
            setCommentInput("");
          }}
        >
          <i
            style={{
              fontSize: "16px",
              color: "#84c3ff",
            }}
            className="fa-solid fa-paper-plane"
          ></i>
        </div>
      </AddCommentInputContainer>
      {hasComments && (
        <CommentsContainer>
          {scheduleData?.comments.map((comment) => {
            return (
              <OverlayTrigger
                key={uuidv4()}
                placement="top"
                overlay={
                  <Tooltip
                    style={{
                      position: "absolute",
                      fontSize: "11px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "7px",
                        }}
                      >
                        <i
                          style={{
                            fontSize: "9px",
                          }}
                          className="fa-solid fa-user"
                        ></i>
                        <span
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {comment.user}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "7px",
                        }}
                      >
                        <i
                          style={{
                            fontSize: "9px",
                          }}
                          className="fa-solid fa-calendar"
                        ></i>
                        <span
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {comment.date}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "7px",
                          fontStyle: "italic",
                        }}
                      >
                        <i
                          style={{
                            fontSize: "9px",
                          }}
                          className="fa-solid fa-comment"
                        ></i>
                        <span
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {comment.text}
                        </span>
                      </div>
                    </div>
                  </Tooltip>
                }
              >
                <CommentContainer>
                  <i
                    style={{
                      fontSize: "13px",
                      color: "#7b7b7b",
                    }}
                    className="fa-regular fa-comment"
                  ></i>
                  <span
                    style={{
                      flex: 1,
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                  >
                    {comment.text}
                  </span>
                  <div
                    className="delete-container"
                    onClick={() => {
                      const newComments = scheduleData.comments.filter(
                        (c) => c._id !== comment._id
                      );
                      addOrUpdateUserDaySchedule({
                        ...scheduleData,
                        user: user.value,
                        date: moment(new Date(date)).format("YYYY-MM-DD"),
                        _id: scheduleData?._id, //backend checks if _id is truthy, if not, it creates a new schedule
                        tourGroups: scheduleData?.tourGroups || [],
                        comments: newComments,
                        notify: false,
                      });
                    }}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </div>
                </CommentContainer>
              </OverlayTrigger>
            );
          })}
        </CommentsContainer>
      )}
      <ButtonRow>
        <DayOffButton
          isDayOff={isDayOff}
          onClick={() => {
            addOrUpdateUserDaySchedule({
              ...scheduleData,
              user: user.value,
              date: moment(new Date(date)).format("YYYY-MM-DD"),
              isDayOff: !isDayOff,
              isLeave: false,
              _id: scheduleData?._id, //backend checks if _id is truthy, if not, it creates a new schedule
              tourGroups: [],
              notify: true,
            });
          }}
        >
          {isDayOff ? "Remove Day Off" : "Day Off"}
        </DayOffButton>
        <LeaveButton
          isDayOff={isLeave}
          onClick={() => {
            addOrUpdateUserDaySchedule({
              ...scheduleData,
              user: user.value,
              date: moment(new Date(date)).format("YYYY-MM-DD"),
              isLeave: !isLeave,
              isDayOff: false,
              _id: scheduleData?._id, //backend checks if _id is truthy, if not, it creates a new schedule
              tourGroups: [],
              notify: true,
            });
          }}
        >
          {isLeave ? "Remove Leave" : "Leave"}
        </LeaveButton>
      </ButtonRow>
    </div>
  );
};
export default CalendarBoxOverlay;
