import styled from "styled-components";
import SortableTableTourGroup from "./SortableTableTourGroup";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Tooltip from "@mui/material/Tooltip";
import {
  useFetchEntities,
  useGetAvailabilityOfProductByDateAndStartTimeId,
  useAddTourGroup,
} from "../../../../reactQueryHooks";
import { RotatingLines } from "react-loader-spinner";
import MenuIcon from "@mui/icons-material/Menu";
import NavButtonWithCustomMenu from "./NavButtonWithCustomMenu";
import MenuItem from "@mui/material/MenuItem";
import AddCardIcon from "@mui/icons-material/AddCard";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import NoTransferIcon from "@mui/icons-material/NoTransfer";
import { useAxios } from "../../../../axiosProvider";

//----------------------------------------------------------------

const GroupOfTourGroupsContainer = styled.div`
  padding: 0 0 10px 0;
  flex: 1;
`;

const InnerContainer = styled.div`
  padding: 10px 12px;
  //border: 2px solid #dbdbdb;
  //background-color: rgb(247 247 247);
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  display: flex;
  //if isFleetMapShown is true, flex direction is column
  flex-direction: column;
  gap: 7px;
  //option_title should disappear when @media width is less than 500px
  .option_title {
    @media (max-width: 800px) {
      display: none;
    }
  }
`;

const TourGroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const ActivityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  flex: 1;
  font-size: 11px;
  //padding:0 0 0 3px;
  span {
    color: rgb(25 114 202);
    letter-spacing: 0.2px;
    font-weight: 800;
  }
  .fa-bars {
    cursor: pointer;
  }
`;

//------------------------------------------------------------------------

const GroupOfTourGroupsWithTimeLine = ({
  groupOfTourGroups,
  isFleetMapShown,
  isBookingsMinimized,
  refetchTourGroups,
  date,
  time,
}) => {
  const { user } = useAxios();
  const userIsAdmin = user.isAdmin;
  const userIsPermittedToCreateNewTourGroups =
    user.permissions.createNewTourGroups || userIsAdmin;
  const { data: entities } = useFetchEntities();
  // console.log(groupOfTourGroups);
  const product = entities?.data?.products?.find(
    (product) => product._id === groupOfTourGroups?.product_id
  );

  const startTime = product?.start_times?.find(
    (start_time) => start_time._id === groupOfTourGroups?.start_time_id
  );

  const product_option = product?.options?.find(
    (option) => option._id === groupOfTourGroups?.option_id
  );

  const { data: availability, isLoading: isLoadingAvailability } =
    useGetAvailabilityOfProductByDateAndStartTimeId({
      product_id: product._id,
      date: date,
      start_time_id: groupOfTourGroups?.start_time_id,
    });

  const total = groupOfTourGroups?.ticketsSum + availability?.num;

  const { mutateAsync: addTourGroup } = useAddTourGroup();

  const tourGroups = groupOfTourGroups.tour_group_ids.map((tourGroupId) => {
    let ids = [];
    groupOfTourGroups.tour_group_ids.forEach((id, index) => {
      if (id !== tourGroupId) {
        ids.push({
          tour_group_id: id,
          group_number: index + 1,
        });
      }
    });
    return {
      tourGroupId: tourGroupId,
      ids: ids,
    };
  });

  //------------------------------------------------------------------------

  return (
    <GroupOfTourGroupsContainer>
      <InnerContainer>
        <ActivityHeader>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "5px",
            }}
          >
            <NavButtonWithCustomMenu
              title={
                <MenuIcon
                  sx={{
                    cursor: "pointer",
                    color: "rgb(25 114 202)",
                    fontSize: "25px",
                    padding: "0 5px",
                    borderRadius: "50%",
                    backgroundColor: "rgb(25 114 202 / 10%)",
                    transition: "all 0.3s",
                    "&:hover": {
                      backgroundColor: "rgb(25 114 202 / 20%)",
                    },
                  }}
                />
              }
            >
              <MenuItem
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "13px",
                }}
                onClick={async () => {
                  try {
                    if (userIsPermittedToCreateNewTourGroups) {
                      await addTourGroup({
                        product_id: product._id,
                        option_id: groupOfTourGroups.option_id,
                        start_time_id: groupOfTourGroups.start_time_id,
                        index: groupOfTourGroups.tour_group_ids.length + 1,
                        date: date,
                        time: time,
                      });
                      refetchTourGroups();
                    } else {
                      alert(
                        "You don't have permission to create new tour groups"
                      );
                    }
                  } catch (error) {
                    console.log(error);
                    alert(error?.response?.data || error.toString());
                  }
                }}
              >
                <AddCardIcon
                  fontSize="small"
                  sx={{
                    color: "grey",
                  }}
                />
                Create Group
              </MenuItem>
            </NavButtonWithCustomMenu>

            <span
              style={{
                flex: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {product?.title}
            </span>

            <span>
              <i
                style={{
                  fontSize: "10px",
                }}
                className="fa-solid fa-clock"
              ></i>{" "}
              {startTime?.time_slot}
            </span>

            <span className="option_title">{`(${
              product_option?.title || "N/A"
            })`}</span>
          </span>

          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "4px",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip
              title={`TOTAL PAX ${groupOfTourGroups.ticketsSum}`}
              placement="top"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
                cursor: "pointer",
              }}
            >
              <>
                <PeopleAltIcon color="primary" sx={{ fontSize: 13 }} />
                <span>{groupOfTourGroups.ticketsSum}</span>
              </>
            </Tooltip>

            {isLoadingAvailability && (
              <RotatingLines height="12" width="12" strokeColor="grey" />
            )}
            {!isLoadingAvailability && availability && availability.num > 0 && (
              <>
                <Tooltip title={`MAX CAPACITY ${total}`} placement="top">
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                  >{`/ ${total}`}</span>
                </Tooltip>
                <Tooltip
                  title={`AVAILABLE SEATS ${
                    total - groupOfTourGroups.ticketsSum
                  }`}
                  placement="top"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                    cursor: "pointer",
                  }}
                >
                  <>
                    <EventSeatIcon color="primary" sx={{ fontSize: 13 }} />
                    <span>{`${total - groupOfTourGroups.ticketsSum}`}</span>
                  </>
                </Tooltip>

                {product_option?.requires_vehicle && (
                  <Tooltip
                    title={
                      groupOfTourGroups?.acc_vehicles_max_capacity > 0
                        ? `ALL VEHICLES CAPACITY IS ${groupOfTourGroups?.acc_vehicles_max_capacity}`
                        : "VEHICLE HAS NOT BEEN SET"
                    }
                    placement="top"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4px",
                      cursor: "pointer",
                    }}
                  >
                    {groupOfTourGroups?.acc_vehicles_max_capacity > 0 ? (
                      <span>
                        <DirectionsBusIcon
                          color="primary"
                          sx={{ fontSize: 13 }}
                        />
                        <span>
                          {groupOfTourGroups?.acc_vehicles_max_capacity}
                        </span>
                      </span>
                    ) : (
                      <NoTransferIcon
                        color="primary"
                        sx={{ fontSize: 13, color: "indianred" }}
                      />
                    )}
                  </Tooltip>
                )}
              </>
            )}
            {!isLoadingAvailability &&
              availability &&
              availability.num === 0 && (
                <Tooltip
                  title="AVAILABILITY CLOSED OUT"
                  placement="top"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    cursor: "pointer",
                  }}
                >
                  {window.innerWidth > 900 ? (
                    <span>
                      <EventSeatIcon
                        color="primary"
                        sx={{ fontSize: 13, color: "indianred" }}
                      />
                      <span
                        style={{
                          color: "indianred",
                        }}
                      >
                        CLOSED
                      </span>
                    </span>
                  ) : (
                    <DoDisturbOffIcon
                      sx={{ fontSize: 13, color: "indianred" }}
                    />
                  )}
                </Tooltip>
              )}
          </span>

          {/* <SortableTableOptionsMenu tourGroup={tourGroup} /> */}
        </ActivityHeader>
        <TourGroupsContainer isFleetMapShown={isFleetMapShown}>
          {tourGroups.map((tourGroup) => {
            return (
              <SortableTableTourGroup
                ids={tourGroup.ids}
                key={tourGroup.tourGroupId}
                tourGroupId={tourGroup.tourGroupId}
                refetchPage={refetchTourGroups}
                isBookingsMinimized={isBookingsMinimized}
              />
            );
          })}
        </TourGroupsContainer>
      </InnerContainer>
    </GroupOfTourGroupsContainer>
  );
};

export default GroupOfTourGroupsWithTimeLine;
