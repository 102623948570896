import { useEffect, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import { RotatingLines } from "react-loader-spinner";
import {
  useSortBookingsInTourGroup,
  useHideTourGroup,
} from "../../../../reactQueryHooks";
import SortableTableOptionsMenu from "./SortableTableOptionsMenu";
import SortableTableInfo from "./SortableTableInfo";
import BookingRow from "./BookingRow";
import { useAxios } from "../../../../axiosProvider";
import { useFetchTourGroupById } from "../../../../reactQueryHooks";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

//--------------------------------------------------------------------

const Wrapper = styled.div`
  flex: 1;
  flex-basis: 49%;
  display: flex;
  flex-direction: column;
  user-select: none;
  gap: 5px;
  font-size: 12px;
  border-radius: 13px;

  .sorting-handler {
    cursor: move;
    padding: 0 5px;
  }

  .row-placeholder {
    background-color: white;
    border: 2px dashed lightpink;
    height: 44px;
  }
  .ui-sortable-helper {
    display: table;
    //first td
    td {
      background-color: #ffffa2;
      height: 40px;
      padding: 5px;
    }
  }

  @media (max-width: 600px) {
    .hide_on_mobile {
      display: none !important;
    }
  }
`

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px;
  background-color: rgb(242 242 242);
  border-radius: 13px;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  //position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 6px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const TableContainer = styled.div`
  //flex: 1; //SHOW TABLE FULL HEIGHT --> ROWS BECOME OVERGROWN AND TAKE UP TOO MUCH SPACE
  width: 100%;
  display: flex;
  padding: 8px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  //border: 2px solid rgb(255, 255, 255);
  /* border-top: thin solid hsl(0, 0%, 91%);
  border-left: thin solid hsl(0, 0%, 91%);
  border-right: thin solid hsl(0, 0%, 91%); */

  //box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  //box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    cursor: default;
    thead {
      //background-color: red;
      //border: thin solid lightgray;
      th {
        text-align: center;
        font-size: 10px;
        padding-bottom: 2.8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        //background-color: whitesmoke;
      }
    }
  }
`;

const SpinnerContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  /* background-color: whitesmoke;
  padding: 6px; */
  border-radius: 7px;
  .skeleton-container {
    width: 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }
`;

//---------------------------------------------------------------------------------

const SortableTableTourGroup = ({
  ids,
  tourGroupId,
  refetchPage,
  isBookingsMinimized,
}) => {
  const { user } = useAxios()
  const userIsAdmin = user.isAdmin
  const userIsPermittedToSortBookings =
    user.permissions.sortBookingsInDayPlanner || userIsAdmin
  const userIsPermittedToHideTourGroups =
    user.permissions.hideTourGroups || userIsAdmin
  const {
    data: tourGroup = {
      bookings: [],
      assignees: [],
      notes_list: [],
    },
    isFetching,
    refetch: refetchTourGroup,
  } = useFetchTourGroupById(tourGroupId)

  const { mutateAsync: sortBookingsInTourGroup } = useSortBookingsInTourGroup();
  const { mutateAsync: hideTourGroup, isLoading: isHidingTourGroup } =
    useHideTourGroup();
  const bookings = tourGroup.bookings;
  const tableRef = useRef(null);
  const activityToClass = `${tourGroup?.product_id}-${tourGroup?.start_time_id}`
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase();

  useEffect(() => {
    $(tableRef.current).sortable({
      //keep element width when sorting with cloning
      helper: (e, tr) => {
        var $originals = tr.children()
        var $helper = tr.clone()
        $helper.children().each(function (index) {
          $(this).width($originals.eq(index).width())
        })
        return $helper
      },
      connectWith: `.${activityToClass}`,
      //before start sorting
      start: (event, ui) => {
        if (!userIsPermittedToSortBookings) {
          alert("You are not permitted to sort bookings")
          return
        }
        //all tables that cannot receive the sorted row,will hide content and show message
        const unReceivableTables = $(
          `.table-wrapper:not(:has(.${activityToClass}))`
        )
        const unReceivableTablesContent = unReceivableTables.find(
          ".table-wrapper-content"
        )
        unReceivableTablesContent.css({
          opacity: "0",
        })
        const unReceivableTablesContainer =
          unReceivableTables.find(".cannot-sort-here")
        unReceivableTablesContainer.css({
          background: "mistyrose",
        })
      },
      beforeStop: () => {
        //revert all start() effects
        $(`.table-wrapper-content`).css({
          opacity: "1",
        })
        $(".cannot-sort-here").css({
          background: "whitesmoke",
        })
      },
      //containment: ".table-container",
      //handle: ".sorting-handler",
      //handle with click and drag
      placeholder: "row-placeholder",

      // ↓ runs when sorting stops,returning the table where the row was picked from
      //stop: (event, ui) => {},
      // ↓ runs when a table receives a row,returning the table that received the row
      //receive: (event, ui) => {},
      update: async (event, ui) => {
        //every table that gets updated runs this function
        //im getting the array of bookings ids from both the tables that got updated if booking switched tables
        //or only from the table that got updated if booking got sorted inside the same table
        const idFromDataAttr = event.target.dataset.id
        const ids = $(event.target).sortable("toArray")
        //const filteredIds filter out empty strings from the array
        const filteredIds = ids.filter((id) => id !== "")

        const payload = {
          tour_group_id: idFromDataAttr,
          booking_ids: filteredIds,
        }

        await sortBookingsInTourGroup(payload).then((res) => refetchTourGroup())
      },
    })
    // eslint-disable-next-line
  }, [isFetching])

  //---------------------------------------------------------------------------------

  return tourGroup?._id ? (
    <Wrapper data-id={tourGroup._id} className={"table-wrapper"}>
      {isFetching && !isBookingsMinimized && (
        <SpinnerContainer>
          <Skeleton
            height={40}
            baseColor={"rgb(230 230 230)"}
            borderRadius={7}
            duration={0.7}
            highlightColor={"white"}
            containerClassName="skeleton-container"
          />
          <Skeleton
            //count={3}
            height={100}
            baseColor={"rgb(230 230 230)"}
            duration={0.7}
            borderRadius={7}
            highlightColor={"white"}
            containerClassName="skeleton-container"
          />
        </SpinnerContainer>
      )}
      {!isFetching && !isBookingsMinimized && (
        <Container
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className={"cannot-sort-here"}
        >
          <ContentContainer className={"table-wrapper-content"}>
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "13px",
                fontWeight: "600",
                color: "indianred",
                letterSpacing: "0.7px",
                opacity: 0,
              }}
              className={"cannot-sort-here-msg"}
            >
              Cannot be sorted here
            </span>
            <TableContainer>
              <SortableTableOptionsMenu
                ids={ids}
                tourGroup={tourGroup}
                refetchPage={refetchPage}
                refetchTourGroup={refetchTourGroup}
              />
              <table>
                <thead>
                  <tr>
                    <th>
                      <i className="fa-solid fa-hashtag"></i>
                    </th>
                    <th>REF</th>
                    <th>Client</th>
                    <th>Seats</th>
                    <th className="hide_on_mobile">Location</th>
                    <th>Pickup</th>
                    <th>Time</th>
                    <th className="hide_on_mobile">Channel</th>
                    <th className="hide_on_mobile">Notes</th>
                  </tr>
                </thead>
                <tbody
                  ref={tableRef}
                  className={activityToClass}
                  data-id={tourGroup._id}
                >
                  {bookings.length ? (
                    bookings?.map((booking, index) => {
                      return (
                        <BookingRow
                          key={booking._id}
                          booking={booking}
                          index={index}
                          refetchTourGroup={refetchTourGroup}
                        />
                      )
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="9"
                        style={{
                          textAlign: "center",
                          background: "whitesmoke",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <NewReleasesIcon
                              style={{ color: "red" }}
                              sx={{
                                fontSize: "14px",
                              }}
                            />
                            <span
                              style={{
                                color: "red",
                                fontSize: "11px",
                                fontWeight: "600",
                              }}
                            >
                              No bookings found
                            </span>
                          </div>
                          {isHidingTourGroup ? (
                            <RotatingLines
                              width="17"
                              strokeColor="grey"
                              //speed
                              strokeWidth={4}
                            />
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                fontSize: "10px",
                                backgroundColor: "grey",
                              }}
                              onClick={async () => {
                                if (userIsPermittedToHideTourGroups) {
                                  try {
                                    if (
                                      window.confirm(
                                        "Are you sure you want to hide this group?"
                                      )
                                    ) {
                                      await hideTourGroup(tourGroup._id)
                                      refetchPage()
                                    }
                                  } catch (error) {
                                    //alert(error?.response?.data || error.toString());
                                    toast.error(
                                      error?.response?.data || error.toString(),
                                      {
                                        position: "bottom-center", // Set position to bottom center
                                      }
                                    )
                                  }
                                } else {
                                  alert("You are not permitted to hide groups")
                                }
                              }}
                            >
                              HIDE GROUP
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableContainer>

            <BottomContainer>
              <SortableTableInfo
                tourGroup={tourGroup}
                refetch={refetchTourGroup}
              />
            </BottomContainer>
          </ContentContainer>
        </Container>
      )}
    </Wrapper>
  ) : (
    <SpinnerContainer>
      <Skeleton
        height={40}
        baseColor={"rgb(230 230 230)"}
        borderRadius={7}
        duration={0.7}
        highlightColor={"white"}
        containerClassName="skeleton-container"
      />
      <Skeleton
        //count={3}
        height={100}
        baseColor={"rgb(230 230 230)"}
        duration={0.7}
        borderRadius={7}
        highlightColor={"white"}
        containerClassName="skeleton-container"
      />
    </SpinnerContainer>
  )
}

export default SortableTableTourGroup







  