import styled from "styled-components";
import { motion } from "framer-motion";
import { useState, useMemo, useEffect, useContext } from "react";
import moment from "moment";
import SelectionBar from "../../../selection_bar/SelectionBar";
import DayPlannerTourGroupsBarContent from "../bar/DayPlannerTourGroupsBarContent";
import FleetTrackingContent from "../../fleet_tracking/content/FleetTrackingContent";
import SchedulePlannerContent from "../../schedule_planner/content/SchedulePlannerContent";
import AvailabilitiesContent from "../../availabilities/AvailabilitiesContent";
import CalendarNotesContent from "../../calendar_notes/CalendarNotesContent";
import GroupOfTourGroups from "./GroupOfTourGroups";
import GroupOfTourGroupsWithTimeLine from "./GroupOfTourGroupsWithTimeLine";
import BarLoader from "react-spinners/BarLoader";
import {
  useFetchTourGroupIdsBySpecificDate,
  useFetchEntities,
} from "../../../../reactQueryHooks";
import { AppContext } from "../../../../App";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  overflow: hidden;
`;

const Page = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  //max-width: 1800px;
  /* @media (min-width: 1000px) {
    padding: 0 5px 0 0;
  } */
  overflow-x: hidden;
  //padding: ${(props) => (props.isfleetmapshown ? "0" : "0 5px 0 0")};

  /* ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => (props.isfleetmapshown ? "#dbdbdb" : "#91c9ff")};
    // border-radius: 2px;
    border-radius: ${(props) => (props.isfleetmapshown ? "0" : "2px")};
  } */
`;

const TourGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 5px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const NoBookingsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  h6 {
    color: ${(props) => props.theme.colors.red};
    font-weight: 600;
    font-size: 13px;
  }
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  /* @media (min-width: 1440px) {
    opacity: 1;
  } */

  opacity: 0.3;
  &:hover {
    opacity: 1;
    filter: brightness(1.1);
    transition: opacity 0.2s;
    transition: filter 0.2s;
  }

  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.green};
  color: white;
  font-size: 30px;
  border: none;
  cursor: pointer;

  i {
    font-size: 20px;
  }
  //shadow
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

//------------------------------------------------------------------------

const DayPlannerTourGroupsContent = () => {
  const { data: entities } = useFetchEntities();
  const [showTourGroupsWithTimeline, setShowTourGroupsWithTimeline] =
    useState(true);
  const [isFleetMapShown, setIsFleetMapShown] = useState(false);
  const [isScheduleShown, setIsScheduleShown] = useState(false);
  const [isAvailabilitiesShown, setIsAvailabilitiesShown] = useState(false);
  const [isNotesShown, setIsNotesShown] = useState(false);
  const [isBookingsMinimized, setIsBookingsMinimized] = useState(false);
  const {
    dayPlannerDate: date,
    setDayPlannerDate: setDate,
    isBookingFormOpen,
    setBookingFormBookingId,
    setIsBookingFormOpen,
  } = useContext(AppContext);
  const scheduleDate = useMemo(
    () => moment(date).startOf("month").format("YYYY-MM-DD"),
    [date]
  );

  const {
    data: tourGroupsBySpecificDate,
    isFetching: isFetchingBySpecificDate,
    refetch: refetchTourGroups,
  } = useFetchTourGroupIdsBySpecificDate(date);

  const sortedTourGroupsBySpecificDate = useMemo(() => {
    if (tourGroupsBySpecificDate) {
      return tourGroupsBySpecificDate.sort((a, b) => {
        const product1 = entities?.data?.products?.find(
          (product) => product._id === a.product_id
        );

        const startTime1 = product1?.start_times?.find(
          (start_time) => start_time._id === a.start_time_id
        );
        const product2 = entities?.data?.products?.find(
          (product) => product._id === b.product_id
        );
        const startTime2 = product2?.start_times?.find(
          (start_time) => start_time._id === b.start_time_id
        );

        const time1 = moment(startTime1?.time_slot, "HH:mm");
        const time2 = moment(startTime2?.time_slot, "HH:mm");
        return time2 - time1;
      });
    } else {
      return [];
    }
  }, [tourGroupsBySpecificDate, entities]);

  const handleDateChange = (action) => {
    if (action === "next") {
      setDate(moment(date).add(1, "days").format("YYYY-MM-DD"));
    } else {
      setDate(moment(date).subtract(1, "days").format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setIsNotesShown(false);
        setIsFleetMapShown(false);
        setIsScheduleShown(false);
        setIsAvailabilitiesShown(false);
        setShowTourGroupsWithTimeline(false);
      }
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsNotesShown, setIsFleetMapShown, setIsScheduleShown]);

  //------------------------------------------------------------------------

  return (
    <Container>
      <SelectionBar>
        <DayPlannerTourGroupsBarContent
          isModalOpen={isBookingFormOpen}
          date={date}
          setDate={setDate}
          handleDateChange={handleDateChange}
          isNotesShown={isNotesShown}
          setIsNotesShown={setIsNotesShown}
          isFleetMapShown={isFleetMapShown}
          setIsFleetMapShown={setIsFleetMapShown}
          isAvailabilitiesShown={isAvailabilitiesShown}
          setIsAvailabilitiesShown={setIsAvailabilitiesShown}
          isScheduleShown={isScheduleShown}
          setIsScheduleShown={setIsScheduleShown}
          isBookingsMinimized={isBookingsMinimized}
          setIsBookingsMinimized={setIsBookingsMinimized}
          showTourGroupsWithTimeline={showTourGroupsWithTimeline}
          setShowTourGroupsWithTimeline={setShowTourGroupsWithTimeline}
        />
      </SelectionBar>
      <ContentWrapper>
        <Content
          style={{
            position: "relative",
            flex: 1,
          }}
        >
          <Page
            style={{
              padding: "0 5px 0 0",
            }}
            isfleetmapshown={isFleetMapShown.toString()}
          >
            {!isFetchingBySpecificDate &&
              sortedTourGroupsBySpecificDate?.length > 0 && (
                <TourGroupsContainer>
                  {sortedTourGroupsBySpecificDate.map((groupOfTourGroups) => {
                    const key = groupOfTourGroups.tour_group_ids.join("");

                    return showTourGroupsWithTimeline ? (
                      <GroupOfTourGroupsWithTimeLine
                        key={key}
                        groupOfTourGroups={groupOfTourGroups}
                        isBookingsMinimized={isBookingsMinimized}
                        isFleetMapShown={isFleetMapShown}
                        refetchTourGroups={refetchTourGroups}
                        date={date}
                        time={groupOfTourGroups.time}
                      />
                    ) : (
                      <GroupOfTourGroups
                        key={key}
                        groupOfTourGroups={groupOfTourGroups}
                        isBookingsMinimized={isBookingsMinimized}
                        isFleetMapShown={isFleetMapShown}
                        refetchTourGroups={refetchTourGroups}
                        date={date}
                        time={groupOfTourGroups.time}
                      />
                    );
                  })}
                </TourGroupsContainer>
              )}
            {isFetchingBySpecificDate && (
              <SpinnerContainer>
                <BarLoader color="#606060" loading={true} />
              </SpinnerContainer>
            )}
            {!isFetchingBySpecificDate &&
              tourGroupsBySpecificDate?.length === 0 && (
                <NoBookingsContainer>
                  <h6>No bookings for this day</h6>
                </NoBookingsContainer>
              )}
          </Page>
          {!isBookingFormOpen && (
            <AddButton
              onClick={() => {
                setBookingFormBookingId(null);
                setIsBookingFormOpen(true);
              }}
            >
              <i className="fa-solid fa-plus"></i>
            </AddButton>
          )}
        </Content>

        {isFleetMapShown && (
          <Content
            style={{
              flex: 1,
              margin: "5px 5px 5px 0px",
              backgroundColor: "white",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px 0 rgba(0, 0, 0, 0.3)",
            }}
          >
            <Page>
              <FleetTrackingContent date={date} />
            </Page>
          </Content>
        )}

        {isAvailabilitiesShown && (
          <Content
            style={{
              minWidth: "400px",
              maxWidth: "400px",
              margin: "5px 5px 5px 0px",
              backgroundColor: "white",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px 0 rgba(0, 0, 0, 0.3)",
            }}
          >
            <Page
              style={{
                padding: 0,
              }}
            >
              <AvailabilitiesContent date={date} />
            </Page>
          </Content>
        )}

        {isScheduleShown && (
          <Content
            style={{
              flex: 1,
              margin: "5px 5px 5px 0px",
              backgroundColor: "white",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px 0 rgba(0, 0, 0, 0.3)",
            }}
          >
            <Page>
              <SchedulePlannerContent
                startDate={scheduleDate}
                selectedDate={date}
              />
            </Page>
          </Content>
        )}

        {isNotesShown && <CalendarNotesContent date={date} />}
      </ContentWrapper>
    </Container>
  );
};

export default DayPlannerTourGroupsContent;
